<template>
    <div class="row mx-0" :key="componentKey">
        <div class="col-lg-4 py-1">
            <div class="row">
                <div class="col-3 px-1">
                    <input :id="'date-sm_' + id" type="text" v-model="tag" class="form-control date-sm"
                           @change="update" :readonly="!can_edit">
                </div>
                <div class="col-9">
                    <div class="row">
                        <div class="col-3 px-1">
                            <input :id="'von_' + id" @change="update" v-model="von" type="text" :readonly="!can_edit"
                                   class="input-time form-control text-center von"/>
                        </div>
                        <div class="col-3 px-1">
                            <input :id="'bis_' + id" @change="update" v-model="bis" type="text" :readonly="!can_edit"
                                   class="time input-time form-control text-center"/>
                        </div>
                        <div class="col-3 px-1">
                            <div class="input-group input-duration">
                                <input type="text" name="dauer" v-model="dauer" tabindex="-1" readonly="readonly"
                                       disabled="disabled" class="dauer form-control text-center px-1 " min="0"
                                       :id="'dauer_' + id">
                                <div class="input-group-append">
                                    <span class="input-group-text">m</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 px-1">
                            <div class="input-group input-duration">
                                <input type="number" min="0" step="1" v-model="unverrechenbar" tabindex="-1"
                                       @change="update" :max="dauer" :id="'unverrechenbar_' + id" :readonly="!can_edit"
                                       class="dauer form-control text-center px-1">
                                <div class="input-group-append">
                                    <span class="input-group-text">m</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-8 py-1">
            <div class="row">
                <div class="col-4 px-1">
                    <multiselect @update:modelValue="updateUser" v-model="selected_user" :options="users" :disabled="!can_edit"
                                 :custom-label="userLabel" required label="id" track-by="id" deselectLabel=""
                                 selectLabel="" :allow-empty="false" :optionsLimit="50">
                    </multiselect>
                </div>
                <div v-bind:class="[can_delete ? 'col-7' : 'col-8']">
                    <div class="row">
                        <div class="col-3 px-1">
                            <multiselect @update:modelValue="updateCode" v-model="selected_code" :options="workcodes" :disabled="!can_edit"
                                         :custom-label="codeLabel" required label="id" track-by="id" :showLabels="false"
                                         :allow-empty="false" :optionsLimit="30">
                            </multiselect>
                        </div>
                        <div class="col-9 px-1">
                            <input type="text" @change="update" name="kommentar" class="form-control px-1"
                                   v-model="kommentar" :id="'kommentar_' + id" :readonly="!can_edit"
                                   @keyup.enter="update">
                        </div>
                    </div>
                </div>
                <div class="col-1 px-1" v-if="can_delete">
                    <button class="btn btn-block btn-outline-danger" tabindex="-1" data-toggle="modal"
                            data-target="#drop_modal" :data-time-id="id">
                        <i class="far fa-trash-alt"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import timetracker from "../timetracker";
    import Multiselect from 'vue-multiselect'

    export default {
        components: {
            Multiselect
        },
        props: ['timeData', 'abteil_id', 'abteil', 'users', 'projects', 'workcodes', 'can_delete','can_edit', 'can_view_mandat'],

        data() {
            return {
                id: '',
                tag: '',
                von: '',
                dauer: '',
                unverrechenbar: '',
                bis: '',
                componentKey: 0,
                drop_modal_id: '',
                mandat_id: 0,
                user_id: 0,
                code_id: 0,
                kommentar: "",
                selected_code: {
                    id: 0,
                    code_kurz: 0
                },
                selected_user: {
                    id: 0,
                    first_name: 0,
                    last_name: 0
                },
                errors: {},
                filtered_projects: this.projects,
            }
        },
        methods: {
            forceRenderer() {
                this.componentKey += 1;
            },
            async loadData() {
                this.id = this.timeData.id;
                this.tag = timetracker.dateConverter(this.timeData.tag);
                this.von = this.timeData.von.substr(0, 5);
                this.dauer = this.timeData.dauer;
                this.code_id = this.timeData.code_id;
                this.unverrechenbar = this.timeData.unverrechenbar;
                this.bis = timetracker.calcBis(this.timeData.von, this.timeData.dauer);
                this.drop_modal_id = "#drop_modal_" + this.id;
                this.kommentar = this.timeData.kommentar;

                if (this.abteil === "user") {
                    this.user_id = this.abteil_id;
                    this.mandat_id = this.timeData.mandat_id;
                } else if (this.abteil === "mandat") {
                    this.mandat_id = this.abteil_id;
                    this.user_id = this.timeData.user_id;
                }

                const user = this.users.find(user => user.id == this.user_id);
                this.selected_user = {
                    id: this.user_id,
                    first_name: user.first_name,
                    last_name: user.last_name,
                };

                const workcode = this.workcodes.find(workcode => Number(workcode.id) === Number(this.code_id));
                this.selected_code = {
                    id: this.code_id,
                    code_kurz: workcode.code_kurz,
                    comment_required: workcode.comment_required,
                };
            },
            updateUser(user) {
                this.selected_user = user;
                this.update();
            },
            updateCode(code) {
                this.selected_code = code;
                this.update();
            },
            async update() {
                this.von = timetracker.fixTime(this.von);
                this.bis = timetracker.fixTime(this.bis);
                this.dauer = timetracker.calcDauer(this.von, this.bis);

                let errors = timetracker.checkRules(this);
                let error_keys = Object.keys(errors);

                if(error_keys.length > 0){
                    this.$emit('errors', errors);
                    flash('Fehler beim Update', 'danger');

                    timetracker.markErrors(errors, this.id);
                    return;
                } else {
                    //remove classes from previous warnings
                    jQuery('.border-danger').removeClass('border-danger');
                    jQuery('.border').removeClass('border');

                    this.$emit('errors', {});
                }

                let comp = this;

                await axios.patch(`/api/zeiterfassung/${this.id}`, {
                    'tag': this.tag,
                    'von': this.von,
                    'bis': this.bis,
                    'unverrechenbar': this.unverrechenbar,
                    'mandat_id': this.mandat_id,
                    'user_id': this.selected_user.id,
                    'code_id': this.selected_code.id,
                    'dauer': this.dauer,
                    'kommentar': this.kommentar,
                }).then(response => {
                    if(response.status === 200) {
                        flash('Update erfolgreich', 'success');
                    }
                }).catch(error => {
                    comp.$emit('errors', error.response.data);
                    flash('Fehler beim Update', 'danger');
                });

            },
            /**
             * @purpose for multi select
             * @returns {string}
             */
            userLabel: timetracker.userLabel,
            codeLabel: timetracker.codeLabel,
        },
        created() {
            this.loadData();
        },
        mounted() {
            let vm = this;
            let von_field = jQuery('#von_' + vm.id)

            von_field.timepicker({
                'timeFormat': 'H:i',
                'step': 15
            }).on('changeTime', function () {
                let newVal = jQuery('#von_' + vm.id).val();
                if (newVal !== vm.von) {
                    vm.von = newVal;
                    vm.update()
                }
            });

            jQuery('#bis_' + vm.id).timepicker({
                'timeFormat': 'H:i',
                'step': 15,
                'minTime': von_field.val(),
            }).on('changeTime', function () {
                let newVal = jQuery('#bis_' + vm.id).val();
                if (newVal !== vm.bis) {
                    vm.bis = newVal;
                    vm.update()
                }
            });

            jQuery("#date-sm_" + vm.id).datepicker({
                beforeShow: function (input, inst) {
                    jQuery(document).off('focusin.bs.modal');
                },
                onClose: function () {
                    jQuery(document).on('focusin.bs.modal');
                },
                dateFormat: 'dd.mm.y',
                changeMonth: true,
                changeYear: true,
                yearRange: "-5:+20", // last hundred years
                firstDay: 1, // Start with Monday
                monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
                dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
                dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                onSelect: function (dateText) {
                    vm.tag = dateText;
                    vm.update()
                }
            });
        },
    }
</script>
