<template>
  <div class="flash-container">
    <div :class="'alert alert-' + state + ' flash-message'" role="alert" v-show="show">
      {{ body }}
      <a href="#" class="close" @click="hide" aria-label="close">&times;</a>
    </div>
  </div>
</template>

<script>
let emitter = require('tiny-emitter/instance');
export default {
  props: ['message'],
  data() {
    return {
      show: false,
      body: '',
      state: '',
    }
  },
  created() {
    if (this.message) {
      this.flash(this.message);
    }

    emitter.on('flash', (message) => this.flash(message))
  },
  methods: {
    flash(message) {
      this.show = true;
      this.body = message.message;
      this.state = message.state;

      setTimeout(() => {
        this.hide()
      }, 4000)
    },
    hide() {
      this.show = false
    }
  }
}
</script>

<style>
.spacing {
  position: fixed;
  right: 25px;
  bottom: 25px;
}
</style>
