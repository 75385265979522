<template>
    <div class="row mx-0" :key="componentKey">
        <div class="col-lg-4 py-1">
            <div class="row">
                <div class="col-3 px-1">
                    <input id="tag_neu" type="text" v-model="tag" class="form-control" readonly>
                </div>
                <div class="col-9">
                    <div class="row">
                        <div class="col-3 px-1">
                            <input id="von_create" v-model="von" type="text" readonly
                                   class="input-time form-control text-center"/>
                        </div>
                        <div class="col-3 px-1">
                            <input id="bis_create" v-model="bis" type="text" readonly
                                   class="input-time form-control text-center"/>
                        </div>
                        <div class="col-3 px-1">
                            <div class="input-group input-duration">
                                <input type="text" name="dauer" v-model="dauer" tabindex="-1" readonly="readonly"
                                       disabled="disabled" class="dauer form-control text-center px-1 " min="0">
                                <div class="input-group-append">
                                    <span class="input-group-text">m</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 px-1">
                            <div class="input-group input-duration">
                                <input type="number" min="0" step="1" v-model="unverrechenbar" tabindex="-1"
                                       :max="dauer" readonly class="dauer form-control text-center px-1">
                                <div class="input-group-append">
                                    <span class="input-group-text">m</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-8 py-1">
            <div class="row">
                <div class="pl-1 col-5 pr-1">
                    <multiselect @update:modelValue="selectNext('code_id')" v-model="selected_project"
                                 :options="filtered_projects"
                                 :custom-label="projectLabel" required label="id" track-by="id" :show-labels="false"
                                 selectLabel="" :allow-empty="false" :optionsLimit="5" :limit="10"
                                 placeholder="bitte wählen">
                    </multiselect>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-3 px-1">
                            <multiselect @update:modelValue="selectNext('kommentar_stoppuhr')" v-model="selected_code"
                                         :options="workcodes"
                                         :custom-label="codeLabel" required label="id" track-by="id" id="code_id"
                                         selectLabel="" :allow-empty="false" :optionsLimit="3" placeholder="wählen">
                            </multiselect>
                        </div>
                        <div class="col-9 px-1">
                            <input type="text" name="kommentar" class="form-control px-1" id="kommentar_stoppuhr"
                                   v-model="kommentar" @keyup.enter="create" @focus="kommentarFocus"
                                   @blur="kommentarLooseFocus">
                        </div>
                    </div>
                </div>
                <div class="col-1 px-1 position-relative">
                    <button @click="create" id="stoppuhr-stop" data-toggle="tooltip" data-placement="bottom"
                            :disabled="!creationAllowed"
                            class="btn btn-success btn-block" data-original-title="Stoppuhr anhalten">
                        <i class="far fa-save"></i>
                    </button>
                    <button class="btn btn-outline-secondary btn-block stoppuhr-option" id="stoppuhr-pause"
                            @click="pauseStoppuhr()"
                            type="button" data-toggle="tooltip" data-placement="top"
                            title="Stoppuhr pausieren">
                        <i class="fa fa-circle-notch fa-spin"></i>
                    </button>
                    <span data-toggle="modal" data-target="#confirm-abort">
                        <button class="btn btn-outline-danger btn-block stoppuhr-option" id="stoppuhr-abort"
                                type="button" data-toggle="tooltip" data-placement="top"
                                title="Stoppuhr abbrechen">
                            <i class="fas fa-times"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>
        <div class="modal fade" id="confirm-abort" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
             aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="myModalLabel">Stoppuhr abbrechen</h4>
                    </div>
                    <div class="modal-body">
                        <p>Wollen Sie die Stoppuhr wirklich abbrechen?</p>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">
                            Nein, zurück zur Stoppuhr
                        </button>
                        <button type="button" name="löschen-ok" @click="abortStoppuhr()"
                                class="btn btn-danger btn-ok">
                            Ja, Stoppuhr abbrechen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import timetracker from "../timetracker";
import Multiselect from "vue-multiselect";

export default {
    name: "StopWatch",
    components: {
        Multiselect
    },
    props: ['projects', 'workcodes', 'user_id'],
    data() {
        return {
            tag: timetracker.getNow().dateSm,
            von: timetracker.getNow().time,
            dauer: 1,
            unverrechenbar: 0,
            bis: timetracker.getNow().time,
            componentKey: 0,
            selected_project: null,
            selected_code: null,
            kommentar: "",
            start: new Date(),
            pause: false,
            pauseStart: null,
            previous_interval: -1,
            filtered_projects: {},
        }
    },
    methods: {
        forceRenderer() {
            this.componentKey += 1;
        },
        loadData() {
            // take only active projects
            this.filtered_projects = this.projects.filter(project => (project.status === 0));

        },
        /**
         * @purpose Stoppuhr anhalten und weiterlaufen lassen, Dauer in Unverrechenbar schreiben.
         * @author SamuelSchwegler
         */
        pauseStoppuhr() {

            let stoppuhr_pause_i = jQuery("#stoppuhr-pause > i");
            let stoppuhr_pause = jQuery("#stoppuhr-pause");

            stoppuhr_pause.blur();

            // falls zustand ist in "Pause"
            if (this.pause) {
                // die Pause wird automatisch bei Unverrechenbar verrechnet
                stoppuhr_pause_i.toggleClass('fa-play', false);
                stoppuhr_pause_i.toggleClass('fa-circle-notch', true);
                stoppuhr_pause_i.toggleClass('fa-spin', true);
                stoppuhr_pause.attr('data-original-title', 'Stoppuhr pausieren').tooltip('show');

                this.pause = false;
            } else {
                this.previous_interval = -1;
                this.pauseStart = new Date();
                this.pause = true;

                stoppuhr_pause_i.toggleClass('fa-play', true);
                stoppuhr_pause_i.toggleClass('fa-pause', false);
                stoppuhr_pause_i.toggleClass('fa-spin', false);
                stoppuhr_pause.attr('data-original-title', 'Stoppuhr weiterlaufen lassen').tooltip('show');
            }
        },
        /**
         * @purpose Bis und Dauer setzten
         * @context Wird alle 5s von startStoppuhr aufgerufen
         * @author SamuelSchwegler
         */
        updateClock() {
            let now = new Date();

            this.dauer = Math.ceil(new Date(now - this.start).getTime() / (1000 * 60));
            this.bis = timetracker.getNow().time;

            if (this.pause) {
                let interval = Math.ceil(new Date(now - this.pauseStart).getTime() / (1000)) % 60;
                if (this.previous_interval > interval) {
                    this.unverrechenbar += 1;
                }
                this.previous_interval = interval;
            }
        },
        abortStoppuhr() {
            jQuery('#confirm-abort').modal('hide');

            // Remove navigation prompt
            window.onbeforeunload = null;

            this.$emit('toggleStopWatch', false);
        },
        selectNext(id) {
            jQuery(`#${id}`).focus();
        },
        kommentarFocus() {
            jQuery('#kommentar_stoppuhr').width('calc(100% - 0px)');
            jQuery("#stoppuhr-abort").hide();
            jQuery("#stoppuhr-pause").hide();
        },

        kommentarLooseFocus() {
            jQuery('#kommentar_stoppuhr').width('calc(100% - 120px)');
            jQuery("#stoppuhr-abort").show();
            jQuery("#stoppuhr-pause").show();
        },

        async create() {
            this.von = timetracker.fixTime(this.von);
            this.bis = timetracker.fixTime(this.bis);
            this.dauer = timetracker.calcDauer(this.von, this.bis);

            if (!this.creationAllowed) {
                return;
            }

            await axios.post(`/api/zeiterfassung/`, {
                'tag': this.tag,
                'von': this.von,
                'bis': this.bis,
                'unverrechenbar': this.unverrechenbar,
                'mandat_id': this.selected_project.id,
                'user_id': this.user_id,
                'code_id': this.selected_code.id,
                'dauer': this.dauer,
                'kommentar': this.kommentar,
            }).then(response => {
                if (response.status === 200) {
                    flash('Eintrag erfolgreich', 'success');

                    // Remove navigation prompt
                    window.onbeforeunload = null;

                    this.$emit('toggleStopWatch', false);
                }
            }).catch(error => {
                this.$emit('errors', error.response.data);
                flash('Fehler beim Eintrag', 'danger');
            });
        },
        codeLabel: timetracker.codeLabel,
        projectLabel: timetracker.projectLabel,
    },
    created() {
        this.loadData();
    },
    mounted() {
        // start stoppuhr
        this.updateClock();

        setInterval(this.updateClock, 2000);
    },
    computed: {
        creationAllowed() {
            return !(this.dauer < 0 || this.unverrechenbar > this.dauer
                || this.selected_project === null || this.selected_code === null ||
                (this.selected_code.comment_required === "1" && this.kommentar === ""));
        }
    }
}
</script>

<style scoped>

</style>
