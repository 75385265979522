require('./bootstrap');

import { createApp } from 'vue'

const app = createApp({
    /* root component options */
})
app.component('flash', require('./components/Flash.vue').default);
app.component('time-user-table', require('./components/TimeUserTable.vue').default);
app.component('time-mandat-table', require('./components/TimeMandatTable.vue').default);
app.mount('#vue-app')


const emitter = require('tiny-emitter/instance');
window.flash = function (message, state) {
    emitter.emit('flash', {message, state});
};

