var timetracker = {
    getNow() {
        const today = new Date();
        const date = today.getFullYear() + '-' + this.addZero(today.getMonth() + 1) + '-' + this.addZero(today.getDate());
        const time = this.addZero(today.getHours()) + ":" + this.addZero(today.getMinutes());
        return {'date': date, 'time': time, 'dateSm': this.dateConverter(date)}
    },
    /**
     * @purpose Fügt einer einstelligen Zahl eine Null an. Bsp bei Uhrzeiten
     * @author SamuelSchwegler
     * @param val
     * @returns {string|*}
     */
    addZero(val) {
        if (val.toString().length === 1 && Number(val) < 10) {
            return '0' + val;
        }
        return val;
    },
    /**
     * calculate duration of worktime from a H:i to a H:i
     * check also for day changes
     */
    calcDauer(von, bis) {
        var parsedBis = this.parseTime(bis);
        var dauer = parsedBis - this.parseTime(von);

        if(!this.parseTime(bis) || !this.parseTime(von)) {
            return -1;
        }
        if (parsedBis < 200 && dauer < 0) {
            dauer = dauer + 24 * 60;
        }

        if(dauer > 24 * 60)
            return -1;

        return dauer;
    },
    /**
     * Parse a time object to mins
     * @param s
     * @returns {number, boolean}
     */
    parseTime(s) {
        var c = s.split(':');
        if(parseInt(c[1]) > 59) {
            return false;
        }
        return parseInt(c[0]) * 60 + parseInt(c[1]);
    },
    fixTime(rawTime) {
        if (/^([0-2][0-9]):([0-5][0-9])$/.test(rawTime)) {
            // for format dd:mm
            return rawTime;
        } else if (/([0-2][0-9][0-5][0-9])$/.test(rawTime)) {
            //for format ddmm
            return rawTime.substr(0, 2) + ':' + rawTime.substr(2, 2);
        } else if (/([0-9]){1}:([0-5][0-9])$/.test(rawTime)) {
            //for format d:mm
            return '0' + rawTime;
        } else if (/([0-9][0-5][0-9])$/.test(rawTime)) {
            //for format dmm
            return '0' + rawTime.substr(0, 1) + ':' + rawTime.substr(1, 2);
        }
        return rawTime;
    },
    fixDate(rawDate) {
        if (/^([0-9]){2}.([0-9]){2}.([0-9]){4}$/.test(rawDate)) {
            // for format dd.mm.yyyy
            return rawDate.substr(0, 8);
        }
        return rawDate;
    },
    /**
     * @purpose converts YYYY-MM-DD to dd.mm.yy
     * @author SamuelSchwegler
     * @param dateInput
     */
    dateConverter(dateInput) {
        var p = dateInput.split(/\D/g);
        return [this.addZero(p[2]), this.addZero(p[1]), p[0].substr(2, 2)].join(".")
    },
    calcBis(von, dauer) {
        var p = von.split(':');
        var hours = ((Number(dauer) + Number(p[1])) / 60 + Number(p[0]));
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);

        return this.addZero(rhours % 24) + ':' + this.addZero(rminutes);
    },

    /**
     * @purpose applies rules on row
     * @param element
     * @returns {Array}
     */
    checkRules(element) {
        let error = {};

        if (!/^([0-9]){2}:([0-9]){2}$/.test(element.bis)) {
            error.bis = 'Bis ist im falschen Format';
        }

        if (!/^([0-3][0-9])(.)([0-1][0-9])(.)([0-9]){2}$/.test(element.tag)) {
            error.tag = 'Tag muss im Format dd.mm.yy sein';
        }

        if (element.dauer < 0 || Number.isNaN(element.dauer)) {
            error.dauer = 'Dauer muss positiv sein';
        }

        if (element.unverrechenbar > element.dauer) {
            error.unverrechenbar = 'Unverrechenbar darf nicht grösser sein als Dauer.';
        }

        if (element.selected_code === null) {
            error.code = ['Arbeitscode fehlt'];
        } else if (Number(element.selected_code.comment_required) === 1 &&
            (element.kommentar === "" || element.kommentar === null)) {
            error.kommentar = 'Bei Arbeit "' + element.selected_code.code_kurz + '" muss man einen Kommentar angeben.';
        }

        return error;
    },

    /**
     * @purpose Mark and focus element where a problem occures
     * @param errors
     * @param id
     */
    markErrors(errors, id) {
        //remove classes from previous warnings
        jQuery('.border-danger').removeClass('border-danger');
        jQuery('.border').removeClass('border');

        let keys = Object.keys(errors); // get all fields with problems
        keys.forEach(function (key, index) {
            let element = jQuery('#' + key + '_' + id);
            element.addClass('border border-danger');

            if (index === 0) {
                element.focus(); // set cursor to first field with problem
            }
        });

        return true;
    },

    /**
     * @purpose for multi select
     * @returns {string}
     */
    projectLabel(args) {
        return `${args.id}, ${args.bezeichnung}, ${args.klient.name}`;
    },
    userLabel(args) {
        return `${args.last_name}, ${args.first_name}`;
    },
    codeLabel({id, code_kurz}) {
        return `${code_kurz}`
    },


};

export default timetracker;
